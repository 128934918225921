import EditModal, {
  BaseEditModalProps,
  SaveCompletedHandler,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import { SaveClientDocument, SaveClientMutation, SaveClientMutationVariables } from 'graphql-operations';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ClientDetailEdit, { EditFormData, validate, validateWarnings } from './ClientDetailEdit';
import { ClientType } from './ClientDetailView';

interface ClientDetailEditModalProps extends BaseEditModalProps {
  title: string;
  client?: ClientType;
}

const data2variables: VariablesExtractor<EditFormData, SaveClientMutationVariables> = data => {
  const {
    id,
    degreeBefore,
    degreeAfter,
    streetNumberP,
    streetNumberO,
    cardId,
    ccp,
    phone,
    phone2,
    email,
    gdprSignDate,
    note
  } = data;

  if (!streetNumberP && !streetNumberO) {
    console.error('Both streetNumberP and streetNumberO are undefined', data);
    throw new Error('Both streetNumberP and streetNumberO are undefined');
  }

  return {
    id,
    degreeBefore,
    degreeAfter,
    firstName: failOnMissing(data, 'firstName'),
    lastName: failOnMissing(data, 'lastName'),
    birthNumber: failOnMissing(data, 'birthNumber'),
    street: failOnMissing(data, 'street'),
    streetNumberP,
    streetNumberO,
    city: failOnMissing(data, 'city'),
    zip: failOnMissing(data, 'zip'),
    mcp4: failOnMissing(data, 'mcp4'),
    sex: failOnMissing(data, 'sex'),
    birthday: failOnMissing(data, 'birthday'),
    ccp,
    cardId,
    phone,
    phone2,
    email,
    gdprSignDate,
    note,
    citizenshipId: failOnMissing(data, 'citizenship').id
  };
};

export default function ClientDetailEditModal({ title, client, children }: ClientDetailEditModalProps) {
  const history = useHistory();

  const handleCompleted: SaveCompletedHandler<SaveClientMutation, EditFormData> = useCallback(
    (data, apolloClient) => {
      // go to detail only when creating new item
      if (!client) {
        history.push('/clients/' + data.saveClient.id);
        apolloClient.resetStore();
      }
    },
    [client, history]
  );

  return (
    <EditModal<EditFormData, SaveClientMutationVariables, SaveClientMutation>
      title={title}
      size="lg"
      tag={ClientDetailEdit}
      initialData={{
        mcp4: false,
        ...(client || {}).personData,
        ...(client || {}).clientData
      }}
      validate={validate}
      validateWarnings={validateWarnings}
      data2variables={data2variables}
      mutation={SaveClientDocument}
      onCompleted={handleCompleted}
      children={children}
    />
  );
}
